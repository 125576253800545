import service from '../request'
const baseUrl = window.globalUrl.HOME_API;

// 分页查询个人发布的资源资产（项目）信息
export const getPersonalXmxxByPg = (params) => {
  return service.post(`${baseUrl}/trade-website/cqjy/queryPersonalXmxxByPg`, params);
}
export const selectClaimXmxx = (params) => {
  return service.get(`${baseUrl}/trade-website/cqjy/selectClaimXmxx`, params);
}

//修改认领状态
export const updateClaimStatus = (params) => {
  return service({
    method: "post",
    url: `${baseUrl}/trade-website/cqjy/updateClaimStatus`,
    params: params,
  });
}


// 查询项目信息详情
export const getXmxxByBH = (params) => {
  return service({
    method: 'get',
    url: `${baseUrl}/trade-website/cqjy/queryXmxxByBH`,
    params: params
  })
}

//删除项目信息
export const deleteXmByBh = (params) => {
  return service({
    method: "post",
    url: `${baseUrl}/trade-website/cqjy/deleteXmByBh`,
    params: params,
  });
}

//发布新供应
export const publicItemInfo = (params) => {
  return service({
    method: "post",
    url: `${baseUrl}/trade-website/cqjy/publicItemInfo`,
    data: params,
  });
}

//编辑供应
export const updateItemInfo = (params) => {
  return service({
    method: "post",
    url: `${baseUrl}/trade-website/cqjy/updateItemInfo`,
    data: params,
  });
}

// 发布供应
export const postSupplyInfo = (params) => {
  return service({
    method: "post",
    url: `${baseUrl}/land-trustship/orderGroup/save`,
    data: params,
  });
};

// 发布需求
export const postDamendInfo = (params) => {
  return service({
    method: "post",
    url: `${baseUrl}/land-trustship/order/save`,
    data: params,
  });
};

// 获取品种价格树
export const getCategoryTree = (params) => {
  return service({
    method: 'get',
    url: `${baseUrl}/land-trustship/category/tree`,
    params: params
  })
}

// 获取农服价格
export const getServicePrice = (params) => {
  return service({
    method: 'post',
    url: `${baseUrl}/land-trustship/service/price/list`,
    data: params,
    header: {
      'Content-Type': 'application/json'
    }
  })
}

// 获取编号
export const getProjectId = (params) => {
  return service({
    method: 'get',
    url: `${baseUrl}/land-trustship/sequence/get`,
    params: params
  })
}

// 数据字
export const getDict = (params) => {
  return service({
    url: `${baseUrl}/admin/dict/type/${params}`,
    method: 'get',
  })
}


